<template>
  <div class="publish-container">
    <img src="@/assets/order/pass.png" alt="" />
    <img :src="src" alt="" />

    <div class="nopassText" v-if="this.imgShow == 'false'">
      <span>提交成功，审核不通过</span>
      <div>待重新填写信息和上传治疗之后再次进行审核</div>
    </div>
    <div class="passText" v-if="this.imgShow == 'true'">
      <span>提交成功，审核通过</span>
    </div>
    <el-button class="btn" type="primary" @click="returnList()">返回</el-button>
  </div>
</template>

<script>
export default {
  created() {
    this.imgShow = this.$route.query.state;
    this.address = this.$route.query.status;
    if (this.$route.query.state == "true") {
      this.src = this.imgPass;
    } else if (this.$route.query.state == "false") {
      this.src = this.imgNopass;
    }
  },
  data() {
    return {
      imgShow: "",
      address: "",
      src: "",
      imgPass: require("@/assets/order/pass.png"),
      imgNopass: require("@/assets/order/nopass.png"),
    };
  },
  methods: {
    returnList() {
      if (this.address == "BI" && this.address !== "") {
        this.$router.push({
          path: "/User/MyCompetitive",
        });
      }

      if (this.address == "RE" && this.address !== "") {
        this.$router.push({
          path: "/User/MyPublish",
        });
      }
      if (this.address == "AU" && this.address !== "") {
        this.$router.push({
          path: "/User/MyBidding",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.publish-container {
  background: #fff;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;
  height: 720px;
  img {
    position: absolute;
    left: 44%;
    top: 30%;
  }
  .btn {
    position: absolute;
    left: 47%;
    top: 65%;
    width: 140px;
  }
  .nopassText {
    position: absolute;
    left: 39%;
    top: 55%;
    font-size: 20px;
    color: #84bfff;
    line-height: 30px;
    span {
      margin-left: 25%;
    }
  }
  .passText {
    position: absolute;
    left: 38%;
    top: 55%;
    font-size: 20px;
    color: #84bfff;
    line-height: 30px;
    width: 500px;
    span {
      margin-left: 25%;
    }
  }
}
</style>
