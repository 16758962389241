var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "publish-container" },
    [
      _c("img", {
        attrs: { src: require("@/assets/order/pass.png"), alt: "" },
      }),
      _c("img", { attrs: { src: _vm.src, alt: "" } }),
      this.imgShow == "false"
        ? _c("div", { staticClass: "nopassText" }, [
            _c("span", [_vm._v("提交成功，审核不通过")]),
            _c("div", [_vm._v("待重新填写信息和上传治疗之后再次进行审核")]),
          ])
        : _vm._e(),
      this.imgShow == "true"
        ? _c("div", { staticClass: "passText" }, [
            _c("span", [_vm._v("提交成功，审核通过")]),
          ])
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "btn",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.returnList()
            },
          },
        },
        [_vm._v("返回")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }